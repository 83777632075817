import React, { useState, ChangeEvent } from "react";
import {checkReCaptcha, getDirectories, getPath} from "../../store/directories/selectors";
import { useNavigate } from "react-router-dom";
import { useLoginByEmailMutation } from "../../store/auth/auth.api";
import Form from "../../components/Form/Form";
import InputPassword from "../../components/Form/InputPassword";
import {
    getUrlAfterAuthorization,
    getUserEmail,
} from "../../store/user/selectors";
import { endpoints } from "../../store/user/user.api";
import { ComponentProps } from "../../models/models";
import { useActions } from "../../hooks/actions";
import { setTokenCookie } from "../../store/auth/selectors";

interface Props extends ComponentProps {
    changeStep: any;
}

const StepTwo: React.FC<Props> = ({
    entities,
    changeStep,
    currentLanguage,
}) => {
    const navigate = useNavigate();
    const path = getPath();
    const email = getUserEmail();
    const nextUrl =
        getUrlAfterAuthorization() ??
        `/${currentLanguage}/${getPath()[1]}/lk/orders`;
    const [password, setPassword] = useState<string>("");
    const [update, { error, isSuccess, data }] = useLoginByEmailMutation();
    const { updateUserField } = useActions();
    const [updateProfile] = endpoints.getProfile.useLazyQuery();

    const { data: directories } = getDirectories();
    const site_key = directories?.recaptcha?.site_key;

    const onSuccess =  async () => {
        const access_token = data?.payload?.access_token;
        const expires_in = data?.payload?.expires_in;

        if (access_token) {
            setTokenCookie({ access_token, expires_in });
            updateUserField({
                name: "tkn",
                value: access_token,
            });
            await updateProfile();
        }

        navigate(nextUrl);
        updateUserField({
            name: "getUrlAfterAuthorization",
            value: null,
        });
    };


    const sendMail = (data: FormData) => {
        update({
            body: data,
            type: "by_email",
            currentLanguage
        });
    };

    const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.append("email", email);
        formData.append("user_role", path[1]);

        if (site_key && currentLanguage) {
            checkReCaptcha(site_key, "email_verification", (token) => {
                formData.append("g-recaptcha-response", token)
                sendMail(formData);
            });
        }
    };

    return (
        <div className="registration-form__content">
            <div className="registration-form__title-wrapper">
                <h1 className="title title--h1">
                    {entities?.login_enter_password?.value}
                </h1>
            </div>
            <div className="form">
                <Form
                    entities={entities}
                    onSubmit={onSubmit}
                    onSuccess={onSuccess}
                    error={error}
                    isSuccess={isSuccess}
                >
                    {(invalidFields, error) => (
                        <>
                            <div className="form__row">
                                <InputPassword
                                    onChange={setPassword}
                                    id="password"
                                    password={password}
                                    placeholder={
                                        entities?.login_enter_password?.value ||
                                        ""
                                    }
                                    invalidFields={invalidFields}
                                    required
                                    inputClassName={["password-field"]}
                                    error={error}
                                    name="password"
                                    btnClassName="password-field__btn btn-reset"
                                />
                                <p className="registration-form__additional-info">
                                    {entities?.login_enter_password_hint?.value}
                                </p>
                            </div>
                            <div className="form__row form__controls form__controls--full-width form__controls--not-fixed-sm">
                                <div
                                    onClick={() => changeStep("one")}
                                    className="btn btn btn--transparent registration-form__button"
                                >
                                    {entities?.login_return_button?.value}
                                </div>
                                <button
                                    className="btn btn--transparent btn--bg-blue"
                                    type="submit"
                                >
                                    {entities?.login_link_value?.value}
                                </button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
};

export default StepTwo;
